































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import TerminosYCondicionesComponent from "@/components/Common/TerminosYCondicionesComponent.vue";
import VueRecaptcha from "vue-recaptcha";
import { jsPDF } from "jspdf";

interface SelectOption {
  value: number | any;
  label: string;
}

@Component({
  components: {
    VueRecaptcha,
    TerminosYCondicionesComponent
  }
})
export default class Reserva extends Mixins(FormValidator) {
  private reservaCompleta = false;
  private codReserva = "000000001";
  private bTerminos = false;
  private terminosFooter = false;
  private customModel = "no";
  private fechaReserva = "";
  private reserva: any = {};
  private validRut = false;
  private nombreWeb = "";
  private formattedFecha = "";
  private formattedRut = "";
  private reservaUsuario: any = {
    rut: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    telefono: "",
    fecha: "",
    id_bloque: "",
    comuna: "",
    direccion: "",
    tipo: null,
    reservas_adicionales: []
  };
  private selectedBloque: any = {
    id: "",
    horario: "",
    cupo: ""
  };
  private canvas: any = null;
  private img = new Image();

  private selectedFecha: any = null;
  private selectTipo: SelectOption = {
    value: null,
    label: "Seleccione una opción"
  };

  private comunaOptions: SelectOption[] = [];
  private cityList: any[] = [];
  private dataComuna: SelectOption = {
    value: null,
    label: " "
  };
  private listaBloques: any = null;
  private disableButton = true;
  private cantidadSelect = {
    value: 0,
    label: "Selecciona cantidad de reservas"
  };
  private cantidadOption = [
    { value: 1, label: "1" }
  ];
  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.trim();
    this.reservaUsuario.rut = newValue.replace(/\./g, "");
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("selectedFecha")
  private edadWatcher(newValue: any) {
    this.reservaUsuario.fecha = newValue;
    this.reservaUsuario.id_bloque = "";
    this.disableButton = true;
    this.getBloques();
  }
  @Watch("selectTipo")
  private selectTipoWatcher(newValue: any) {
    this.reservaUsuario.tipo = newValue.value;
  }
  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.reservaUsuario.comuna = newValue.label;
  }
  @Watch("cantidadSelect")
  private cantidadSelectWatcher(newValue: any) {
    // console.log(this.cantidadSelect)
    this.reservaUsuario.reservas_adicionales = [];
    if (this.cantidadSelect.value && this.cantidadSelect.value > 1) {
      for (let i = 0; i < this.cantidadSelect.value - 1; i++) {
        this.reservaUsuario.reservas_adicionales.push({
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          rut: "",
          formattedRut: "",
          tipo: null,
          tipo_select: {
            value: null,
            label: "Seleccione una opción"
          }
        });
      }
    }
  }
  @Watch("reservaUsuario.reservas_adicionales", { deep: true })
  private rutAdicionalesWatcher(newValue: any) {
    if (newValue && newValue.length > 0) {
      for (let i = 0; i < newValue.length; i++) {
        //Format Rut
        let tempNewValue = newValue[i].formattedRut.trim();
        this.reservaUsuario.reservas_adicionales[i].rut = tempNewValue.replace(
          /\./g,
          ""
        );
        tempNewValue = tempNewValue.replace(/\./g, "").replace(/-/g, "");
        if (tempNewValue.length > 5) {
          const rut = tempNewValue
            .substring(0, tempNewValue.length - 1)
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
          const dv = tempNewValue.substring(tempNewValue.length - 1);
          newValue[i].formattedRut = `${rut}-${dv}`;
        } else {
          newValue[i].formattedRut = tempNewValue
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            .toUpperCase();
        }

        //Get Tipo
        if (newValue[i].tipo_select && newValue[i].tipo_select.value != null) {
          newValue[i].tipo = newValue[i].tipo_select.value;
        }
      }
    }
    // console.log(newValue);
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getReservaEvento();
      this.linkCanonical(this.nombreWeb);
      this.getComunas();
    } else {
      this.routerGo("Error");
    }
  }

  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical"; 
    canonicalLink.href = 'https://muniarica.cl/reserva/' + nombreWeb; 
    document.head.appendChild(canonicalLink);
  }
  private selectHorario(item: any) {
    if (item.id) {
      this.reservaUsuario.id_bloque = item.id;
      this.disableButton = false;
      this.selectedBloque = item;
    } else {
      this.disableButton = true;
    }
  }
  private selectedBloqueStyle(id) {
    if (this.reservaUsuario.id_bloque == id) {
      return "color:white; background-color: #003da6;";
    }
    return "color: #003da6;";
  }
  private getReservaEvento() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/reserva/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.reserva = res.data;
        const base = process.env.VUE_APP_BASE_URL;
        if (this.reserva.foto) {
          this.reserva.foto = base + this.reserva.foto;
        } else {
          this.reserva.foto = require("@/assets/banner_desktop.webp");
        }
        if (this.reserva.foto_mobile) {
          this.reserva.foto_mobile = base + this.reserva.foto_mobile;
        } else {
          this.reserva.foto_mobile = require("@/assets/banner_mobile.webp");
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.routerGo("Error");
      });
  }
  private getBloques() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get(
        "/reserva/bloques?id=" +
          this.reserva.id +
          "&fecha=" +
          this.reservaUsuario.fecha
      )
      .then((res: any) => {
        this.listaBloques = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.listaBloques = [];
        this.$q.loading.hide();
      });
  }
  private getComunas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("comunas")
      .then((res: any) => {
        res.data.forEach((city: any) => {
          this.cityList.push({
            value: city.id,
            label: city.nombre
          });
        });
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.cityList = [];
        this.$q.loading.hide();
      });
    this.$axios("comunas").then(res => {});
  }

  private reservar() {
    if (
      !this.validateRut(this.reservaUsuario.rut) ||
      this.reservaUsuario.rut.trim() == ""
    ) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su Rut",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.reservaUsuario.tipo) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione un tipo de reserva",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.nombre.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su nombre",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.apellido_paterno.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su apellido paterno",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.telefono.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su teléfono",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.telefono.trim().length < 9) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese un teléfono válido (9 dígitos)",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.email.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su correo",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.validEmail(this.reservaUsuario.email)) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor un correo válido",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.direccion.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su dirección",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.comuna.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su comuna",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (
      this.reservaUsuario.reservas_adicionales &&
      this.reservaUsuario.reservas_adicionales.length > 0
    ) {
      for (
        let j = 0;
        j < this.reservaUsuario.reservas_adicionales.length;
        j++
      ) {
        if (
          this.reservaUsuario.reservas_adicionales[j].rut.trim() == "" ||
          !this.validateRut(this.reservaUsuario.reservas_adicionales[j].rut)
        ) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            text: "Por favor ingrese un Rut válido en las reservas adicionales",
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (
          this.reservaUsuario.reservas_adicionales[j].rut ==
          this.reservaUsuario.rut
        ) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            text: "Por favor ingrese un Rut distinto a la reserva principal",
            confirmButtonText: "Aceptar"
          });
          return;
        }
        let repetido = 0;
        for (
          let k = 0;
          k < this.reservaUsuario.reservas_adicionales.length;
          k++
        ) {
          if (
            this.reservaUsuario.reservas_adicionales[k].rut ==
            this.reservaUsuario.reservas_adicionales[j].rut
          ) {
            repetido++;
          }
        }
        if (repetido > 1) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            text:
              "No es posible repetir los Rut en las reservas adicionales (" +
              this.reservaUsuario.reservas_adicionales[j].formattedRut +
              ")",
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (this.reservaUsuario.reservas_adicionales[j].nombre.trim() == "") {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            text:
              "Por favor ingrese el Nombre del Rut " +
              this.reservaUsuario.reservas_adicionales[j].formattedRut,
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (
          this.reservaUsuario.reservas_adicionales[j].apellido_paterno.trim() ==
          ""
        ) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            text:
              "Por favor ingrese el Apellido Paterno del Rut " +
              this.reservaUsuario.reservas_adicionales[j].formattedRut,
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (!this.reservaUsuario.reservas_adicionales[j].tipo) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            text:
              "Por favor seleccione un tipo de entrada del Rut " +
              this.reservaUsuario.reservas_adicionales[j].formattedRut,
            confirmButtonText: "Aceptar"
          });
          return;
        }
      }
    }
    if (!this.selectedFecha || this.selectedFecha == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione una fecha para la reserva",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.reservaUsuario.id_bloque || this.reservaUsuario.id_bloque == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione un horario",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.customModel == "no" || !this.customModel) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor acepte los términos y condiciones",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });        
    this.$axios
      .post("/reservar", this.reservaUsuario)
      .then((res: any) => {
        window.scrollTo(0, 0);
        this.codReserva = res.data.codigo;
        this.fechaReserva = res.data.fecha;
        this.reservaCompleta = true;
        this.$swal({
          icon: "success",
          title: "Reserva Completada",
          text: "Su reserva ha sido realizada correctamente.",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          window.scrollTo(0, 0);
          // this.$q.loading.show({
          //   delay: 100 // ms
          // });
          // this.$router.go(0);
        });
        this.$q.loading.hide();
      })
      .catch(err => {
        const message = err.response.data.message;
        this.reservaCompleta = false;
        if (message == "INVALID_DATOS_RUT") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un rut válido.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "REGISTRADO") {
          this.$swal({
            icon: "warning",
            title: "Ha ocurrido un error",
            text:
              "El Rut ingresado ya cuenta con una reserva para ese horario.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_FECHA") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text:
              "Por favor ingrese una fecha de nacimiento válida (DD-MM-AAAA).",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_NOMBRE") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un nombre.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_APELLIDO_PATERNO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un apellido paterno.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_TELEFONO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un teléfono.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_EMAIL") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un correo.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "NO_CUPO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ya no hay cupos disponibles para el horario seleccionado.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "ADICIONAL_REGISTRADO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text:
              "El Rut " +
              err.response.data.data +
              " ya cuenta con una reserva para el horario seleccionado.",
            confirmButtonText: "Aceptar"
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ha ocurrido un error, por favor intente nuevamente.",
            confirmButtonText: "Aceptar"
          });
        }
        this.$q.loading.hide();
      });
  }
  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
 
  private mostrarTerminos(footer) {
    this.terminosFooter = footer;
    this.bTerminos = true;
  }
  private onCloseTerminos(info) {
    if (info.footer == true) {
      if (info.response == true) {
        this.customModel = "si";
        this.bTerminos = false;
      } else if (info.response == false) {
        this.customModel = "no";
        this.bTerminos = false;
      } else {
        this.bTerminos = false;
      }
    } else {
      this.bTerminos = false;
    }
  }

  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      this.validRut = false;
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        this.validRut = false;
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      this.validRut = true;
      return true;
    }
    this.validRut = false;
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  
  private filterFnComuna(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunaOptions = this.cityList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }
}
